<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-card elevation="2" min-width="300">
        <v-card-title class="primary--text">
          <v-row class="pa-2" justify="center" align="center">
            <v-avatar>
              <v-img
                :src="'/images/abonten-logo.png'"
                contain
                alt="logo"
                height="54"
              ></v-img>
            </v-avatar>
            abonten
          </v-row>
        </v-card-title>
        <v-card-subtitle class="text-h6 mt-2">Reset Password</v-card-subtitle>
        <v-card-text>
          <v-row>
            <p class="px-2 py-1">
              Please enter the email address associated with Abonten account.
            </p>
          </v-row>
          <v-row>
            <v-col>
              <v-subheader>Email </v-subheader>

              <v-text-field
                type="text"
                v-model.trim="email"
                :rules="emailRules"
                required
                filled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="submitForm"> Continue </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: null,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    submitForm() {},
  },
};
</script>

<style scoped>
.container {
  padding: 0;
  margin: 0;
  background: white;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-card__title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 30px;
}
.v-card__subtitle {
  padding: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: black !important;
}
</style>
